import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { SLICE_RESERVATION_DATA } from './constant'
import { apiFetchUnitPrice } from '@/services/PropertyService'
import { UnitPriceItem } from '@/utils/hooks/useUnitPrice'

export type GetUnitPriceState = {
  loading: boolean
  apiData: any
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialState: GetUnitPriceState = {
  loading: false,
  apiData: null,
  status: 'idle',
  error: null
}

interface GetUnitPriceProps {
  propertyId: number
  pricingPlanId: number
  unitId?: number
  startDate?: string
  endDate?: string
  date?: string
  token?: string
}

export const fetchGetUnitPrice = createAsyncThunk(
  `${SLICE_RESERVATION_DATA}/GetUnitPrice`,
  async (props: GetUnitPriceProps) => {
    const response = await apiFetchUnitPrice(props)
    return response.data
  }
)

export const getUnitPriceSlice = createSlice({
  name: `${SLICE_RESERVATION_DATA}/getReservationData`,
  initialState,
  reducers: {
    updateUnitPrice: (state, action: PayloadAction<UnitPriceItem[]>) => {
      const newPrices = action.payload

      // Filter out existing prices that match the `from_date` and `unit_info.id` of the new prices
      const updatedPrices =
        state.apiData?.data?.filter(
          (existingPrice: UnitPriceItem) =>
            !newPrices.some(
              newPrice =>
                newPrice.from_date === existingPrice.from_date &&
                newPrice.unit_info.id === existingPrice.unit_info.id
            )
        ) || []

      // Add new prices to the filtered list
      state.apiData = {
        ...state.apiData,
        data: [...updatedPrices, ...newPrices]
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetUnitPrice.pending, state => {
        state.status = 'loading'
        state.loading = true
      })
      .addCase(fetchGetUnitPrice.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
        state.loading = false
      })
      .addCase(fetchGetUnitPrice.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
        state.loading = false
      })
  }
})

export const { updateUnitPrice } = getUnitPriceSlice.actions

export default getUnitPriceSlice.reducer
