import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_BOOKING_ENGINE_RES } from './constant'
import appConfig from '@/configs/app.config'
import { clearDataIfUnauthorized } from '@/utils/accessToken'

export type GetBookingEngineReservationState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetBookingEngineReservationState: GetBookingEngineReservationState =
  {
    currentRouteKey: '',
    apiData: [],
    status: 'idle',
    error: null
  }
// Define an async thunk for fetching API data
export const fetchGetBookingEngineReservationTypeApiData = createAsyncThunk(
  `${GET_BOOKING_ENGINE_RES}/fetchGetBookingEngineReservationTypeApiData`,
  async (_, thunkAPI) => {
    const { getState } = thunkAPI
    const state: any = getState()
    const token = state?.reservation?.guestUser?.guestUser?.token

    const config = {
      url: 'reservation/get-reservation-for-booking-engine/',
      method: 'get',
      baseURL: appConfig.reservationApiPrefix
    }

    return await clearDataIfUnauthorized(config, thunkAPI, token, 'Token')
  }
)

export const GetBookingEngineReservationTypeSlice = createSlice({
  name: `${GET_BOOKING_ENGINE_RES}/getBookingEngineRes`,
  initialState: initialGetBookingEngineReservationState,
  reducers: {
    setCurrentGetBookingEngineReservationRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetBookingEngineReservationTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(
        fetchGetBookingEngineReservationTypeApiData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(
        fetchGetBookingEngineReservationTypeApiData.rejected,
        (state, action) => {
          state.status = 'failed'
          state.error = action.error.message || null
        }
      )
  }
})

export const { setCurrentGetBookingEngineReservationRouteKey } =
  GetBookingEngineReservationTypeSlice.actions

export default GetBookingEngineReservationTypeSlice.reducer
