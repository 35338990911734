import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PROMOCODE_BOOKING_ENGINE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'

export type GetReservationDetailState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetReservationDetailState: GetReservationDetailState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetReservationDetailApiData = createAsyncThunk(
  `${PROMOCODE_BOOKING_ENGINE}/fetchGetReservationDetailApiData`,
  async (reservation_id: number) => {
    const config = {
      'accept-language': getLocale(),
      url: `reservation/reservation-detail/${reservation_id}/`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetReservationDetailSlice = createSlice({
  name: `${PROMOCODE_BOOKING_ENGINE}/promocodeBookingEngine`,
  initialState: initialGetReservationDetailState,
  reducers: {
    setCurrentGetReservationDetailRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetReservationDetailApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetReservationDetailApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetReservationDetailApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export const { setCurrentGetReservationDetailRouteKey } =
  GetReservationDetailSlice.actions

export default GetReservationDetailSlice.reducer
