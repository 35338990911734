import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_CITY_TAX } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetCityTaxState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialCityTaxState: GetCityTaxState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetCityTaxApiData = createAsyncThunk(
  `${GET_CITY_TAX}/fetchGetCityTaxApiData`,
  async ({ id, noToken }: { id: any; noToken?: boolean }, thunkAPI) => {
    if (!id) return
    const accessToken = noToken ? null : await getAccessToken(thunkAPI)

    const config = {
      url: `boards/get-city-tax/?property_id=${id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        ...(noToken ? {} : { Authorization: `Bearer ${accessToken}` })
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetCityTaxSlice = createSlice({
  name: `${GET_CITY_TAX}/getCityTax`,
  initialState: initialCityTaxState,
  reducers: {
    setCurrentGetCityTaxRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetCityTaxApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetCityTaxApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetCityTaxApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export const { setCurrentGetCityTaxRouteKey } = GetCityTaxSlice.actions

export default GetCityTaxSlice.reducer
