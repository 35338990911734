import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_BOOKINGENGINE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'

export type GetBookingEngineState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetBookingEngineState: GetBookingEngineState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetBookingEngineTypeApiData = createAsyncThunk(
  `${GET_BOOKINGENGINE}/fetchGetBookingEngineTypeApiData`,
  async (prop_id: any) => {
    const config = {
      url: `booking-engine/booking-engine-data/?property_code=${prop_id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json'
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetBookingEngineTypeSlice = createSlice({
  name: `${GET_BOOKINGENGINE}/getBookingEngine`,
  initialState: initialGetBookingEngineState,
  reducers: {
    setCurrentGetBookingEngineRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetBookingEngineTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetBookingEngineTypeApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetBookingEngineTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export const { setCurrentGetBookingEngineRouteKey } =
  GetBookingEngineTypeSlice.actions

export default GetBookingEngineTypeSlice.reducer
